import config from 'config'
import { useScheme } from 'hooks'
import { getExperimentBucket } from 'hooks/experiments/optimizely-utils'
import { useEffect, useMemo, useState } from 'react'
import { reasonForPurchaseMap } from 'types/global'

const reasonOptionsForExperiment = [
  {
    value: 'BuyingSelling',
    icon: '🚗',
    label: 'Buying/Selling/Test driving',
  },
  {
    value: 'Emergency',
    icon: '‼️',
    label: 'Emergency',
  },
  {
    value: 'MainDriverUnable',
    icon: '🍷',
    label: 'Main driver cannot drive (e.g drinking)',
  },
  {
    value: 'Work',
    icon: '👷‍♂️',
    label: 'Driving for work',
  },
  {
    value: 'Impound',
    icon: '👮‍♀️',
    label: 'Impound',
  },
  {
    value: 'Sharing',
    icon: '👥',
    label: 'Sharing the drive',
  },
  {
    value: 'Maintenance',
    icon: '🔧',
    label: 'Maintenance/repairs (e.g. MOT)',
  },
  {
    value: 'AlternativeInsurance',
    icon: '📅',
    label: 'Alternative to annual insurance',
  },
  {
    value: 'Recreation',
    icon: '✈️',
    label: 'Recreation (e.g. holiday...)',
  },
  {
    value: 'TransportingItems',
    icon: '🚙',
    label: 'Transporting items/moving house',
  },
  {
    value: 'Other',
    icon: '❓',
    label: 'Other (please tell us more)',
  },
] as const

const reasonOptionValues = reasonOptionsForExperiment.reduce(
  (acc, option) => ({
    ...acc,
    [option.value]: option.value,
  }),
  {} as { [K in NewReasonOptionValue]: string },
)

export type NewReasonOptionValue = (typeof reasonOptionsForExperiment)[number]['value']

export type NewReasonOptionLabel = (typeof reasonOptionsForExperiment)[number]['label']

const getReasonOptionsRandomisedOrderForExperiment = () => {
  // Create a copy of the options array to avoid mutating the original
  const shuffledOptions = [...reasonOptionsForExperiment]

  // We want Other to be the last value
  // So remove 'Other' option for now
  const otherOption = shuffledOptions.find((option) => option.value === 'Other')
  const optionsWithoutOther = shuffledOptions.filter(
    (option) => option.value !== 'Other',
  )

  // Fisher-Yates shuffle algorithm for remaining options
  for (let i = optionsWithoutOther.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[optionsWithoutOther[i], optionsWithoutOther[j]] = [
      optionsWithoutOther[j],
      optionsWithoutOther[i],
    ]
  }

  // Add 'Other' option back at the end
  return otherOption
    ? [...optionsWithoutOther, otherOption]
    : optionsWithoutOther
}

const REASON_FOR_COVER_EXPERIMENT_ID = 5333014670999552
const REASON_FOR_COVER_ORIGINAL = '5895964624420864'
const REASON_FOR_COVER_VARIANTA = '6354653005217792'
const REASON_FOR_COVER_VARIANTB = '6522323075858432'

export const useReasonForCoverExperiment = () => {
  const [experimentBucket, setExperimentBucket] = useState<
    'ORIGINAL' | 'VARIANT_A' | 'VARIANT_B'
  >('ORIGINAL')
  const { scheme } = useScheme()

  useEffect(() => {
    const setupExperiment = () => {
      const isPrivateCarScheme = scheme === 'VehiclePrivateCarBaseScheme'
      if (!isPrivateCarScheme) {
        return
      }

      const bucket = getExperimentBucket(REASON_FOR_COVER_EXPERIMENT_ID) || {
        id: REASON_FOR_COVER_ORIGINAL
      }

      const bucketToUse =
        bucket.id === REASON_FOR_COVER_ORIGINAL
          ? 'ORIGINAL'
          : bucket.id === REASON_FOR_COVER_VARIANTA
          ? 'VARIANT_A'
          : bucket.id === REASON_FOR_COVER_VARIANTB
          ? 'VARIANT_B'
          : 'ORIGINAL'
      setExperimentBucket(bucketToUse)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'experiment.loaded',
          experimentId: REASON_FOR_COVER_EXPERIMENT_ID,
          variationId: bucket.id,
        })
      }
    }

    const onOptimizelyTagLoaded = () => {
      setupExperiment()
    }

    if (config.ENVIRONMENT_NAME !== 'Production') {
      setupExperiment()
      return
    }

    const timeout = setTimeout(setupExperiment, 2000)
    document.addEventListener('optimizely-tag-loaded', onOptimizelyTagLoaded)

    return () => {
      clearTimeout(timeout)
      document.removeEventListener(
        'optimizely-tag-loaded',
        onOptimizelyTagLoaded,
      )
    }
  }, [scheme])

  return {
    reasonForCoverBucket: experimentBucket,
    isOriginal: experimentBucket === 'ORIGINAL',
    isVariantA: experimentBucket === 'VARIANT_A',
    isVariantB: experimentBucket === 'VARIANT_B',
  }
}

export const useReasonOptionsRandomisedForExperiment = () => {
  const reasonOptionsRandomisedOrder = useMemo(
    getReasonOptionsRandomisedOrderForExperiment,
    [],
  )

  return { reasonOptionsRandomisedOrder }
}

export const getReasonForCoverLabelFromValue = (value: string) => {
  if (value === '') {
    return ''
  }

  const option = reasonOptionsForExperiment.find((opt) => opt.value === value)
  return option ? `${option.icon} ${option.label}` : value
}

// We want to continue to send the original value to the backend for the time being
// So this method maps the new value to an original value which the backend is expecting
// If a variant is chosen then at that point a decision needs to be made about whether the backend will need to be updated to accept the new values
// Or the mapping will stay in place.
export const mapNewValueToOriginalValue = (value: NewReasonOptionValue) => {
  switch (value) {
    case 'BuyingSelling':
      return reasonForPurchaseMap.TestDrive

    case 'MainDriverUnable':
    case 'Sharing':
    case 'Recreation':
    case 'TransportingItems':
      return reasonForPurchaseMap.BorrowingSomeonesVehicles

    case 'Work':
      return reasonForPurchaseMap.TemporaryBusinessUseNeeded

    case 'Maintenance':
      return reasonForPurchaseMap.VehicleInForServiceRepair

    case 'AlternativeInsurance':
      return reasonForPurchaseMap.ProblemWithAnnualInsurance

    case 'Impound':
      return reasonForPurchaseMap.VehicleImpoundedByThePolice

    case 'Emergency':
    case 'Other':
    default:
      return reasonForPurchaseMap.Other
  }
}

// For the experiment, we currently want to match the new Emergency option to the original Other value
// We don't want to show the Other field in the UI for Emergency though
// However we do want to write the display value from the Emergency option to the Other field
// So this method is used to determine if we should write the display value to the Other field
// If you want to map more options to the original Other value then you can add them to the array below
export const shouldWriteOtherReasonForCoverToBackend = (value: string) => {
  const decision = [reasonOptionValues.Emergency].includes(value)
  return decision
}
