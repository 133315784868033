import DiscountBanner from 'components/DiscountBanner'
import React from 'react'

interface RiskDataDiscountBannerProps {
  message?: string
  expired?: boolean
  isHours?: boolean
}
const RiskDataDiscountBanner = ({
  message = 'Complete your quote to receive your Tempcover discount',
  expired,
  isHours
}: RiskDataDiscountBannerProps) => (
  <DiscountBanner
    id='teslaDiscountBanner'
    discountExpired={expired != null && expired}
    isHours={isHours != null && isHours}
  >
    {message}
  </DiscountBanner>
)

export default RiskDataDiscountBanner
