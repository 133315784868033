import React from 'react'
import { BannerText, BannerWrapper } from './DiscountBanner.styles'

interface Props {
  discountExpired: boolean
  id: string
  isHours: boolean
}

const DiscountBanner: React.FC<Props> = ({ id, discountExpired,isHours, children }) => (
  <BannerWrapper id={id} expired={discountExpired} isHours={isHours}>
    <BannerText>{children}</BannerText>
  </BannerWrapper>
)

export default DiscountBanner
