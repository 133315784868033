type Environment = 'local' | 'QADev' | 'QARelease' | 'Production'

interface ConfigInterface {
  BASE_API_URL: string
  REVERSE_PROXY_URL: string
  SITE_URL: string
  CUSTOMER_ACCOUNT_URL: string
  CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN: string
  MENTION_ME_URL: string
  MENTION_ME_ID: string
  CUSTOMER_ACCOUNT_URL_HOME: string
  HOST_NAME: string
  HOST_PATH: string
  STRIPE_KEY: string
  GOOGLE_EXPERIMENT_ID: string
  APPLICATION_ID: 1
  AB_TESTS: {
    STEP_LABEL_EXPERIMENT_ID: string
    DATE_OF_BIRTH_EXPERIMENT_ID: string
    TIMER_EXPERIMENT_ID: string
    PRICE_DISPLAY_EXPERIMENT_ID: string
    UPSELL_EXPERIMENT_ID: string
    TRUSTPILOT_EXPERIMENT_ID: string
    SECURITY_EXPERIMENT_ID: string
    LICENCE_EXPERIMENT_ID: string
    ORDER_SUMMARY_EXPERIMENT_ID: string
    CONTACT_MOVED_EXPERIMENT_ID: string
    PRICE_BREAKDOWN_DIRECT_EXPERIMENT_ID: string
    PRICE_BREAKDOWN_CTM_EXPERIMENT_ID: string
    KLARNA_EXPERIMENT_ID: string
    PRICE_COMPARISON_EXPERIMENT_ID: string
    USE_STRIPE_ELEMENTS_EXPERIMENT_ID: string
  }
  IS_CLIENT_SESSION_ENABLED: boolean
  GTM_ID: string
  SESSION_EXPIRY_MINUTES: number
  SENTRY_SETTINGS: {
    SENTRY_DSN: string
    SENTRY_TRACE_SAMPLE_RATE: number
  }
  ENVIRONMENT_NAME: Environment
  CONFUSED_GA_SESSION_COOKIE_NAME: string
  CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION: number
  CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS: number
  CONFUSED_QUICK_QUOTE_RETRY_DELAY: number
  CONFUSED_QUICK_QUOTE_REDIRECT_URL: string
  CUSTOMER_ACCOUNT_ID_SERVER_URL: string
  CUSTOMER_ACCOUNT_API_URL: string
  CUSTOMER_ACCOUNT_API_KEY: string
  PAYMENT_API_URL: string
  GAP_ACTIVATION_LINK: string
  OPTIMIZELY_API_KEY: string
  OPTIMIZELY_ENV_ID: string
  AMPLITUDE_API_KEY: string
}
declare let process: {
  env: {
    REACT_APP_API_URL: string
    REACT_APP_REVERSE_PROXY_URL: string
    REACT_APP_SITE_URL: string
    REACT_APP_CUSTOMER_ACCOUNT_URL: string
    REACT_APP_CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN: string
    REACT_APP_CUSTOMER_ACCOUNT_URL_HOME: string
    REACT_APP_MENTION_ME_URL: string
    REACT_APP_MENTION_ME_ID: string
    REACT_APP_JUDO_API_TOKEN: string
    REACT_APP_HOST_NAME: string
    REACT_APP_HOST_PATH: string
    REACT_APP_STRIPE_KEY: string
    REACT_APP_GOOGLE_EXPERIMENT_ID: string
    REACT_APP_STEP_LABEL_EXPERIMENT_ID: string
    REACT_APP_DATE_OF_BIRTH_EXPERIMENT_ID: string
    REACT_APP_GTM_ID: string
    REACT_APP_TIMER_EXPERIMENT_ID: string
    REACT_APP_SESSION_COOKIE_EXPIRY_MINUTES: number
    REACT_APP_PRICE_DISPLAY_EXPERIMENT_ID: string
    REACT_APP_UPSELL_EXPERIMENT_ID: string
    REACT_APP_TRUSTPILOT_EXPERIMENT_ID: string
    REACT_APP_SECURITY_EXPERIMENT_ID: string
    REACT_APP_LICENCE_EXPERIMENT_ID: string
    REACT_APP_ORDER_SUMMARY_EXPERIMENT_ID: string
    REACT_APP_CONTACT_MOVED_EXPERIMENT_ID: string
    REACT_APP_IS_CLIENT_SESSION_ENABLED: string
    REACT_APP_PRICE_BREAKDOWN_DIRECT_EXPERIMENT_ID: string
    REACT_APP_PRICE_BREAKDOWN_CTM_EXPERIMENT_ID: string
    REACT_APP_KLARNA_EXPERIMENT_ID: string
    REACT_APP_PRICE_COMPARISON_EXPERIMENT_ID: string
    REACT_APP_SENTRY_DSN: string
    REACT_APP_SENTRY_TRACE_SAMPLE_RATE: number
    REACT_APP_ENVIRONMENT_NAME: Environment
    REACT_APP_CONFUSED_GA_SESSION_COOKIE_NAME: string
    REACT_APP_CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION: number
    REACT_APP_CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS: number
    REACT_APP_CONFUSED_QUICK_QUOTE_RETRY_DELAY: number
    REACT_APP_CONFUSED_QUICK_QUOTE_REDIRECT_URL: string
    REACT_APP_CUSTOMER_ACCOUNT_ID_SERVER_URL: string
    REACT_APP_CUSTOMER_ACCOUNT_API_URL: string
    REACT_APP_CUSTOMER_ACCOUNT_API_KEY: string
    REACT_APP_USE_STRIPE_ELEMENTS_EXPERIMENT_ID: string
    REACT_APP_PAYMENT_API_URL: string
    REACT_APP_GAP_ACTIVATION_LINK: string
    REACT_APP_OPTIMIZELY_API_KEY: string
    REACT_APP_OPTIMIZELY_ENV_ID: string
    REACT_APP_AMPLITUDE_API_KEY: string
  }
}

const config: ConfigInterface = {
  BASE_API_URL: process.env.REACT_APP_API_URL,
  REVERSE_PROXY_URL: process.env.REACT_APP_REVERSE_PROXY_URL,
  SITE_URL: process.env.REACT_APP_SITE_URL,
  CUSTOMER_ACCOUNT_URL: process.env.REACT_APP_CUSTOMER_ACCOUNT_URL,
  CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN:
    process.env.REACT_APP_CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN,
  CUSTOMER_ACCOUNT_URL_HOME: process.env.REACT_APP_CUSTOMER_ACCOUNT_URL_HOME,
  MENTION_ME_URL: process.env.REACT_APP_MENTION_ME_URL,
  MENTION_ME_ID: process.env.REACT_APP_MENTION_ME_ID,
  HOST_NAME: process.env.REACT_APP_HOST_NAME,
  HOST_PATH: process.env.REACT_APP_HOST_PATH,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  GOOGLE_EXPERIMENT_ID: process.env.REACT_APP_GOOGLE_EXPERIMENT_ID,
  APPLICATION_ID: 1,
  AB_TESTS: {
    STEP_LABEL_EXPERIMENT_ID: process.env.REACT_APP_STEP_LABEL_EXPERIMENT_ID,
    DATE_OF_BIRTH_EXPERIMENT_ID:
      process.env.REACT_APP_DATE_OF_BIRTH_EXPERIMENT_ID,
    TIMER_EXPERIMENT_ID: process.env.REACT_APP_TIMER_EXPERIMENT_ID,
    PRICE_DISPLAY_EXPERIMENT_ID:
      process.env.REACT_APP_PRICE_DISPLAY_EXPERIMENT_ID,
    UPSELL_EXPERIMENT_ID: process.env.REACT_APP_UPSELL_EXPERIMENT_ID,
    TRUSTPILOT_EXPERIMENT_ID: process.env.REACT_APP_TRUSTPILOT_EXPERIMENT_ID,
    SECURITY_EXPERIMENT_ID: process.env.REACT_APP_SECURITY_EXPERIMENT_ID,
    LICENCE_EXPERIMENT_ID: process.env.REACT_APP_LICENCE_EXPERIMENT_ID,
    ORDER_SUMMARY_EXPERIMENT_ID:
      process.env.REACT_APP_ORDER_SUMMARY_EXPERIMENT_ID,
    CONTACT_MOVED_EXPERIMENT_ID:
      process.env.REACT_APP_CONTACT_MOVED_EXPERIMENT_ID,
    PRICE_BREAKDOWN_DIRECT_EXPERIMENT_ID:
      process.env.REACT_APP_PRICE_BREAKDOWN_DIRECT_EXPERIMENT_ID,
    PRICE_BREAKDOWN_CTM_EXPERIMENT_ID:
      process.env.REACT_APP_PRICE_BREAKDOWN_CTM_EXPERIMENT_ID,
    KLARNA_EXPERIMENT_ID: process.env.REACT_APP_KLARNA_EXPERIMENT_ID,
    PRICE_COMPARISON_EXPERIMENT_ID:
      process.env.REACT_APP_PRICE_COMPARISON_EXPERIMENT_ID,
    USE_STRIPE_ELEMENTS_EXPERIMENT_ID:
      process.env.REACT_APP_USE_STRIPE_ELEMENTS_EXPERIMENT_ID,
  },
  GTM_ID: process.env.REACT_APP_GTM_ID,
  IS_CLIENT_SESSION_ENABLED:
    `${process.env.REACT_APP_IS_CLIENT_SESSION_ENABLED}` === 'true',
  SESSION_EXPIRY_MINUTES:
    process.env.REACT_APP_SESSION_COOKIE_EXPIRY_MINUTES || 30,
  SENTRY_SETTINGS: {
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    SENTRY_TRACE_SAMPLE_RATE: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
  },
  ENVIRONMENT_NAME: process.env.REACT_APP_ENVIRONMENT_NAME,
  CONFUSED_GA_SESSION_COOKIE_NAME:
    process.env.REACT_APP_CONFUSED_GA_SESSION_COOKIE_NAME,
  CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION,
  CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS,
  CONFUSED_QUICK_QUOTE_RETRY_DELAY:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_RETRY_DELAY,
  CONFUSED_QUICK_QUOTE_REDIRECT_URL:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_REDIRECT_URL,
  CUSTOMER_ACCOUNT_ID_SERVER_URL:
    process.env.REACT_APP_CUSTOMER_ACCOUNT_ID_SERVER_URL,
  CUSTOMER_ACCOUNT_API_URL: process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL,
  CUSTOMER_ACCOUNT_API_KEY: process.env.REACT_APP_CUSTOMER_ACCOUNT_API_KEY,
  PAYMENT_API_URL: process.env.REACT_APP_PAYMENT_API_URL,
  GAP_ACTIVATION_LINK: process.env.REACT_APP_GAP_ACTIVATION_LINK,
  OPTIMIZELY_API_KEY: process.env.REACT_APP_OPTIMIZELY_API_KEY,
  OPTIMIZELY_ENV_ID: process.env.REACT_APP_OPTIMIZELY_ENV_ID,
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY,
}

export default config
