export type Option = {
  id?: number | string
  value: number | string
  selected?: boolean
  description: string
  displayOrder?: number
}

export type Title = 'Uninitialised' | 'NotSet' | 'Mr' | 'Miss' | 'Mrs' | 'Ms'

export type DateFormat = 'DD/MM/YYYY' | 'DD-MM-YYYY' | 'YYYY-MM-DD'

export type LicenceType =
  | 'FullUkLicence'
  | 'FullNorthernIrelandLicence'
  | 'FullEuLicence'
  | 'ProvisionalUkLicence'
  | 'FullInternational'

export type ResidencyType =
  | 'Uninitialised'
  | 'NotSet'
  | 'PermanentUkResident'
  | 'PermanentResidentOfCountryInEuAPreviouslyLivedInUk'
  | 'EuEeaCountry'
  | 'Other'

export type LicenceHeldDuration =
  | 'NotSet'
  | 'SixToElevenMonths'
  | 'OneYear'
  | 'TwoYears'
  | 'ThreeYears'
  | 'FourYears'
  | 'FiveToNineYears'
  | 'TenYearsOrMore'

export type VehicleValueBandId = number

export type Scheme =
  | 'NotSet'
  | 'VehiclePrivateCarBaseScheme'
  | 'VehicleLearnerScheme'
  | 'VehiclePrivateCarVinScheme'
  | 'VehicleCommercialVehicleBaseScheme'
  | 'VehicleCommercialVehicleVinScheme'
  | 'VehiclePrivateCarImpoundedScheme'
  | 'VehicleCommercialVehicleImpoundedScheme'
  | 'VehicleCommercialVehicleCourierScheme'
  | 'VehicleMotorhomeBaseScheme'
  | 'VehicleClassic'
  | 'VehicleMotorcycleLearnerAndFullLicenceScheme'
  | 'VehicleForcesDirectPrivateCarVinScheme'
  | 'Home'
  | 'StudentPrivateCarScheme'
  | 'VehiclePrivateCarCarGiantScheme'
  | 'VehiclePrivateCarCazooScheme'
  | 'VehiclePrivateCarDriveawayScheme'
  | 'VehicleCommercialVehicleDriveawayScheme'
  | 'VehiclePrivateCarHomeDeliveryScheme'
  | 'VehicleCommercialVehicleHomeDeliveryScheme'

export type UkResidencyDuration =
  | ''
  | 'NotSet'
  | 'Never'
  | 'OneToNineYears'
  | 'TenYearsOrMore'

export type IdentityCheck = '' | 'drivingLicence' | 'passport'

export type LicenceDuration = {
  id: number
  value: string
  description: string
  yearDiff: number
  order: number
}
export type DurationType =
  | 'hours'
  | 'Hours'
  | 'days'
  | 'Days'
  | 'weeks'
  | 'Weeks'

export type TrustpilotRating = 1 | 2 | 3 | 4 | 5

export type DurationPreset = {
  id: number
  durationLength: number
  durationType: DurationType
  description: string
}

export type PriceBreakdownUnits =
  | 'Insurer premium'
  | 'Breakdown cover'
  | 'Excess reduction'
  | 'Legal expenses cover'
  | 'Tempcover fee'
  | 'Introducer fee'
  | 'Discount applied'
  | 'Gap Insurance'

export enum QuoteJourneyPosition {
  NotSet = 'NotSet',
  CustomerDetailsSubmit = 'CustomerDetailsSubmit',
  CustomerAccountDetailsSubmit = 'CustomerDetailsSubmitCA',
  CustomerPolicyDateChanged = 'CustomerPolicyDateChanged',
  CustomerAccountPolicyDateChanged = 'CustomerPolicyDateChangedCA',
  CustomerPolicyDurationChanged = 'CustomerPolicyDurationChanged',
  CustomerAccountPolicyDurationChanged = 'CustomerPolicyDurationChangedCA',
  CustomerVehicleChanged = 'CustomerVehicleChanged',
  CustomerAccountVehicleChanged = 'CustomerVehicleChangedCA',
  CustomerAddOnsChanged = 'CustomerAddOnsChanged',
  CustomerDirectAccess = 'CustomerDirectAccess',
  CustomerAccountDirectAccess = 'CustomerDirectAccessCA',
  SystemUnderwriterUpdate = 'SystemUnderwriterUpdate',
  SystemDocumentCheckSubmit = 'SystemDocumentCheckSubmit',
  SystemPriceUpSell = 'SystemPriceUpSell',
  SystemPolicyPayment = 'SystemPolicyPayment',
  SystemPriceRefresh = 'SystemPriceRefresh',
}

const reasonForPurchaseValues = [
  'Uninitialised',
  'NotSet',
  'TestDrive',
  'DriveawayAfterBuying',
  'BorrowingSomeonesVehicles',
  'SharedDrivingOnHoliday',
  'TemporaryBusinessUseNeeded',
  'LearningToDriveUnderTuition',
  'CoverInAnEmergency',
  'CourtesyVehicleFromGarage',
  'ShortTermLeaseVehicle',
  'HiredVehicleNeedingInsurance',
  'VehicleImpoundedByThePolice',
  'ObtainVehicleTax',
  'ProblemWithAnnualInsurance',
  'VehicleInForServiceRepair',
  'Other',
  'TestRide',
  'RideawayAfterBuying',
  'BorrowingSomeonesBike',
  'CourtesyBikeFromGarage',
  'BikeIsBeingHiredButNeedsInsurance',
  'BikeIsImpoundedByThePolice',
  'SellingVehicle',
  'BackFromOrGoingToUniversity',
  'SellingABike',
  'MovingHouse',
  'BuyingAndSelling',
  'WorkingOnSiteUsingContractorsVehicle',
] as const

export const reasonForPurchaseMap = reasonForPurchaseValues.reduce(
  (acc, value) => ({
    ...acc,
    [value]: value,
  }),
  {} as Record<
    (typeof reasonForPurchaseValues)[number],
    (typeof reasonForPurchaseValues)[number]
  >,
)

export type ReasonForPurchase = (typeof reasonForPurchaseValues)[number]
