import React from 'react'
import { Grid, GridContainer } from 'unsemantic'
import { useDuration } from 'hooks'
import Styled from 'styled-components'
import { Button } from '../common/Button'
import { ErrorIcon } from '../Message/ErrorIcon'

const Wrapper = Styled.div`
    border: 1px solid olive;
    background:#FDFFF0;
    border-radius: ${(props) => props.theme.formControlBorderRadius};
    padding-top: 10px;
  padding-bottom: 10px;
`

export const LabelContent = Styled.span`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  font-family: ${(props) => props.theme.fontFamily};
  padding: 5px;
  float: left;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
`

export const ImpoundButton = Styled(Button)`
  background-color: ${(props) => props.theme.secondaryColour};
  margin-bottom: 15px;
  width: 95%;
`

export const ImpoundIcon = Styled(ErrorIcon)`
  color: ${(props) => props.theme.secondaryColour};
  border: 2px solid ${(props) => props.theme.secondaryColour};
  border-radius: 50%;
  padding: 5px;
  padding-bottom: 10px;
`

export const ImpoundSwitcher = ({ vehicleReg }) => {
  const { duration, durationType } = useDuration()
  const policyDurationType = durationType && durationType.toLowerCase()

  const impoundedDuration =
    policyDurationType.includes('hour') && duration < 12 ? 12 : duration

  return (
    <Wrapper>
      <GridContainer>
        <Grid desktop='20' mobile='20' mobile-prefix='5' tablet='20'>
          <ImpoundIcon className='fa fa-exclamation-triangle' />
        </Grid>
        <Grid desktop='80' mobile='75' tablet='80'>
          <LabelContent>
            We have different insurance that's designed specifically for
            impounded vehicles
          </LabelContent>
        </Grid>
      </GridContainer>
      <Grid desktop='100'>
        <a
          href={`/?registration=${vehicleReg}&duration=${impoundedDuration}+${policyDurationType}&impounded=true`}
        >
          <ImpoundButton type='button' onClick={() => {}}>
            SEE IMPOUNDED VEHICLE INSURANCE
          </ImpoundButton>
        </a>
      </Grid>
    </Wrapper>
  )
}
