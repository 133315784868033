import 'core-js'
import 'regenerator-runtime/runtime'
import 'raf/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './containers/App'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import '../node_modules/unsemantic/assets/stylesheets/unsemantic-grid-responsive-tablet.css'
import './app.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import { ConnectedRouter } from 'connected-react-router'
import { createHistory } from './history'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import OptimizelyProviderWrapper from './components/OptimizelyProviderWrapper/OptimizelyProviderWrapper'
import config from 'config'
import '@stripe/stripe-js' // Available sitewide to aid fraud prevention
export const history = createHistory()

const queryClient = new QueryClient()

Sentry.init({
  dsn: config.SENTRY_SETTINGS.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: config.ENVIRONMENT_NAME,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.SENTRY_SETTINGS.SENTRY_TRACE_SAMPLE_RATE,
})

const store = configureStore()
const MOUNT_NODE = document.getElementById('app')
ReactDOM.render(
  <OptimizelyProviderWrapper>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>
  </OptimizelyProviderWrapper>,
  MOUNT_NODE,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
