import React, { ReactNode, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { BrowserOptions } from '@amplitude/analytics-types'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import config from 'config'

const amplitudeConfig: BrowserOptions = {
  autocapture: true,
  serverZone: 'EU',
}

export const AmplitudeWrapper = ({ children }: { children: ReactNode }) => {
  const existingUserId = localStorage.getItem('userId')

  useEffect(() => {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1, // Note, this can be overridden within the Amplitude dashboard
    })
    amplitude.add(sessionReplayTracking)
    if (existingUserId) {
      amplitude.init(config.AMPLITUDE_API_KEY, existingUserId, amplitudeConfig)
    } else {
      amplitude.init(config.AMPLITUDE_API_KEY, amplitudeConfig)
    }
  }, [existingUserId])

  return <>{children}</>
}
