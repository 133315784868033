import { useField } from 'formik'
import React, { useEffect } from 'react'
import {
  getReasonForCoverLabelFromValue,
  mapNewValueToOriginalValue,
  shouldWriteOtherReasonForCoverToBackend,
  useReasonOptionsRandomisedForExperiment,
  NewReasonOptionValue,
  NewReasonOptionLabel,
} from 'hooks/experiments/ReasonForCoverExperimentConfig'
import { useRiskData } from 'hooks'
import Select from 'components/@common/Select'
import FormField from 'components/@common/FormField'

interface ReasonForPurchaseVariantAProps {
  onSelect: (name: string, value: string, label: string) => void
}

const getLocalStorageKey = (quoteId: string) =>
  `reasonForPurchase_VariantA_${quoteId}`

export const ReasonForPurchaseVariantA = ({
  onSelect,
}: ReasonForPurchaseVariantAProps) => {
  const [field, meta, helpers] = useField({ name: 'reasonForPurchase' })

  const [fieldOther, metaOther, helpersOther] = useField({
    name: 'reasonForPurchaseOtherText',
  })

  const [fieldVariantA, metaVariantA, helpersVariantA] = useField({
    name: 'reasonForPurchase_VariantA',
  })

  const [fieldVariantAPosition, metaVariantAHelpers, helpersVariantAPosition] =
    useField({
      name: 'reasonForPurchase_VariantAPosition',
    })

  const { reasonOptionsRandomisedOrder } =
    useReasonOptionsRandomisedForExperiment()

  const { quoteId } = useRiskData()

  const setOriginalReasonForPurchase = (
    value: NewReasonOptionValue,
    label: NewReasonOptionLabel,
  ) => {
    // Write derived value aka original value to state
    const originalValue = mapNewValueToOriginalValue(value)
    onSelect('reasonForPurchase', originalValue, label)
    helpers.setValue(originalValue)
  }

  const setOriginalOtherReason = (
    value: NewReasonOptionValue,
    shouldClear: boolean,
  ) => {
    // Write the other value to state if it should be written
    if (shouldWriteOtherReasonForCoverToBackend(value)) {
      helpersOther.setValue(getReasonForCoverLabelFromValue(value))
    } else if (shouldClear) {
      helpersOther.setValue('')
    }
  }

  useEffect(() => {
    const savedValue = localStorage.getItem(
      getLocalStorageKey(quoteId),
    ) as NewReasonOptionValue | null

    if (savedValue) {
      const index = reasonOptionsRandomisedOrder.findIndex(
        (opt) => opt.value === savedValue,
      )
      const option = reasonOptionsRandomisedOrder[index]

      setOriginalReasonForPurchase(savedValue, option.label)

      // Rehydrating (not selection), so don't clear if "Other" is selected, otherwise the customer will need to re-enter the reason.
      const shouldClear = savedValue !== 'Other'
      setOriginalOtherReason(savedValue, shouldClear)

      // No need to write to storage as we've just got the value from there - but set the field value
      helpersVariantA.setValue(savedValue)

      // Select has 'Please select ...' at index 0 so positions need offsetting by +1
      // Conveniently, this means that not found (-1) is adjusted to 0
      const position = index + 1
      // Store the position of the selected option so we can track it later
      helpersVariantAPosition.setValue(position)
    }
  }, [quoteId]) // Only run on mount and if quoteId changes

  return (
    <FormField
      name='reasonForPurchase_VariantA'
      label='Reason for cover'
      isValidIcon={false}
    >
      <Select
        id='ReasonForPurchase_VariantA'
        name='reasonForPurchase_VariantA'
        onChange={(name, value, label, position) => {
          setOriginalReasonForPurchase(
            value as NewReasonOptionValue,
            label as NewReasonOptionLabel,
          )

          // Selection has changed, so clear if "Other" is selected so customers can enter their reason
          setOriginalOtherReason(value as NewReasonOptionValue, true)

          // Write the value to local storage so we can hydrate it later if they return to this form
          // We're sticking it in local storage as we aren't storing on the backend yet so have no other way of retrieving it
          localStorage.setItem(getLocalStorageKey(quoteId), value)

          // Store the position of the selected option so we can track it later
          helpersVariantAPosition.setValue(position)
        }}
        options={reasonOptionsRandomisedOrder.map((option) => {
          return {
            id: option.value,
            value: option.value,
            description: `${option.icon} ${option.label}`,
          }
        })}
        placeholder="Please select the driver's reason for cover"
      />
    </FormField>
  )
}
