import { useEffect, useState } from 'react'
import useScheme from 'hooks/useScheme'
import config from 'config'
import { getExperimentBucket } from './optimizely-utils'

const PRIVATE_CAR_OCCUPATION_ORIGINAL = '5311044940201984'
const PRIVATE_CAR_OCCUPATION_VARIANT = '5028135545077760'
const PRIVATE_CAR_OCCUPATION_EXPERIMENT_ID = 4793667173482496

const usePrivateCarOccupationExperiment = () => {
  const [useVariation, setUseVariation] = useState(false)
  const { scheme } = useScheme()

  useEffect(() => {
    const setupExperiment = () => {
      const isPrivateCarScheme = scheme === 'VehiclePrivateCarBaseScheme'
      if (!isPrivateCarScheme) {
        return
      }

      const bucket = getExperimentBucket(
        PRIVATE_CAR_OCCUPATION_EXPERIMENT_ID,
      ) || {
        id: PRIVATE_CAR_OCCUPATION_ORIGINAL,
      }

      const shouldUseVariation = bucket.id === PRIVATE_CAR_OCCUPATION_VARIANT
      setUseVariation(shouldUseVariation)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'experiment.loaded',
          experimentId: PRIVATE_CAR_OCCUPATION_EXPERIMENT_ID,
          variationId: bucket.id,
        })
      }
    }

    const onOptimizelyTagLoaded = () => {
      setupExperiment()
    }

    if (config.ENVIRONMENT_NAME !== 'Production') {
      setupExperiment()
      return
    }

    const timeout = setTimeout(setupExperiment, 2000)
    document.addEventListener('optimizely-tag-loaded', onOptimizelyTagLoaded)

    return () => {
      clearTimeout(timeout)
      document.removeEventListener(
        'optimizely-tag-loaded',
        onOptimizelyTagLoaded,
      )
    }
  }, [scheme])

  return useVariation
}

export default usePrivateCarOccupationExperiment
