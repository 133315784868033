import initialState from '../../initialState'
import {
  SEND_3D_SECURE_DATA_SUCCESS,
  CREATE_JUDO_PAYMENT_FAILURE,
  SEND_3D_SECURE_DATA,
} from '../JudoPay/constants'
import {
  SEND_EMAIL_QUOTE_SUCCESS,
  UPDATE_EMAIL_RISK_DATA_SUCCESS,
} from '../EmailQuoteModal/constants'
import {
  FRAUD_FAILURE,
  SET_PAYMENT_IN_PROGRESS,
  CLEAR_PAYMENT_ERROR,
  PRICE_MATCH,
  SET_PAYMENT_NOT_IN_PROGRESS,
} from './constants'
import { RESET_PAYMENT_OPTIONS } from './constants'
import { CREATE_APPLE_PAYMENT_FAILURE } from '../ApplePay/constants'
import {
  REQUEST_QUOTE_SUCCESS,
  CREATE_QUOTE_SUCCESS,
  REQUEST_PRICE_SUCCESS,
} from '../App/constants'
import { CREATE_STRIPE_PAYMENT_FAILURE } from '../Stripe/constants'
import {
  KLARNA_FAILURE,
  START_KLARNA,
} from 'components/Klarna/Klarna.constants'

function paymentPageReducer(state = initialState.payment, action) {
  switch (action.type) {
    case UPDATE_EMAIL_RISK_DATA_SUCCESS:
    case SEND_EMAIL_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: true,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: action.emailAddress ? true : false,
      }
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: action.quote.EmailAddress ? true : false,
      }
    case SEND_3D_SECURE_DATA_SUCCESS:
      return {
        ...state,
        Secure: {
          ...state.Secure,
          ...action.response,
        },
      }
    case CREATE_JUDO_PAYMENT_FAILURE:
    case CREATE_APPLE_PAYMENT_FAILURE:
    case CREATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentError: action.errorMessage,
        paymentInProgress: false,
        priceMatch: true,
      }
    case CLEAR_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: '',
      }
    case FRAUD_FAILURE:
      return {
        ...state,
        paymentInProgress: false,
      }
    case SET_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        paymentInProgress: true,
      }
    case SET_PAYMENT_NOT_IN_PROGRESS:
      return {
        ...state,
        paymentInProgress: false,
      }
    case RESET_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentInProgress: false,
        optionsVisible: false,
      }
    case SEND_3D_SECURE_DATA:
      return {
        ...state,
        paymentInProgress: false,
      }
    case PRICE_MATCH:
      return {
        ...state,
        priceMatch: action.isPriceMatching,
      }
    case START_KLARNA:
      return {
        ...state,
        shouldStartKlarnaPayment: action.shouldStartKlarnaPayment,
      }
    case REQUEST_PRICE_SUCCESS:
      if (action.price.CheapestPrice) {
        return {
          ...state,
          klarnaPaymentOptionAvailable:
            action.price.CheapestPrice.KlarnaPaymentOptionAvailable,
        }
      }
      return {
        ...state,
        klarnaPaymentOptionAvailable: action.price.KlarnaPaymentOptionAvailable,
      }
    case KLARNA_FAILURE:
      return {
        ...state,
        klarnaFailure: action.hasKlarnaFailed,
      }
    default:
      return state
  }
}

export default paymentPageReducer
