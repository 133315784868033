import { fetchVehicleListenerSaga } from './containers/Vehicle/saga'
import { fetchVehicleCustomerAccountListenerSaga } from './containers/VehicleCustomerAccount/saga'

import priceSaga from './containers/App/sagas/priceSaga'
import addressSaga from './containers/Address/saga'
import { formValidationSaga as riskDataCollectionValidationSaga } from './containers/RiskDataCollectionPage/sagas/validation'
import { policyConfirmationSaga } from './containers/PolicyConfirmationPage/saga'

import { all } from 'redux-saga/effects'
import applePaySaga from './containers/ApplePay/saga'
import unknownRegPageSaga from './containers/UnknownRegPage/saga'
import judoPaySaga from './containers/JudoPay/saga'

import ocrSaga from './containers/DrivingLicenceCamera/saga'

import fetchRiskDataSaga from './containers/RiskDataCollectionPage/sagas/riskData'
import emailQuoteSaga from './containers/EmailQuoteModal/saga'
import riskDataCollectionSaga from './containers/RiskDataCollectionPage/sagas/saga'
import fetchQuoteSaga from './containers/App/sagas/quoteSaga'
import invalidRegPageSaga from './containers/InvalidRegPage/saga'
import occupationSaga from './containers/Occupation/saga'
import stripeSaga from './containers/Stripe/saga'
import paymentPageSaga from './containers/PaymentPage/saga'
import myLicenceSaga from './containers/MyLicence/saga'
import annualUpsellSaga from './containers/AnnualUpsell/saga'
import marketingPreferencesSaga from './containers/MarketingPreferencesContainer/saga'
import sessionSaga from './containers/App/sagas/sessionSaga'
import klarnaSaga from './components/Klarna/Klarna.saga'
import confusedQuickQuoteSaga from 'containers/App/sagas/confusedQuickQuoteSaga'
import vehicleCarouselSaga from 'components/VehicleCarousel/saga'

//fix saga imports
export default function* rootSaga() {
  yield all([
    riskDataCollectionSaga(),
    fetchVehicleListenerSaga(),
    fetchVehicleCustomerAccountListenerSaga(),
    priceSaga(),
    riskDataCollectionValidationSaga(),
    policyConfirmationSaga(),
    addressSaga(),
    applePaySaga(),
    unknownRegPageSaga(),
    judoPaySaga(),
    ocrSaga(),
    fetchRiskDataSaga(),
    emailQuoteSaga(),
    fetchQuoteSaga(),
    invalidRegPageSaga(),
    occupationSaga(),
    stripeSaga(),
    paymentPageSaga(),
    myLicenceSaga(),
    annualUpsellSaga(),
    marketingPreferencesSaga(),
    sessionSaga(),
    klarnaSaga(),
    confusedQuickQuoteSaga(),
    vehicleCarouselSaga(),
  ])
}
