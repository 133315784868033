import { H2 } from 'components/common/H2'
import { useField } from 'formik'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  getReasonForCoverLabelFromValue,
  mapNewValueToOriginalValue,
  NewReasonOptionLabel,
  NewReasonOptionValue,
  shouldWriteOtherReasonForCoverToBackend,
  useReasonOptionsRandomisedForExperiment,
} from 'hooks/experiments/ReasonForCoverExperimentConfig'
import { useRiskData } from 'hooks'

const Grid = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 20px 0;
  justify-content: center;
  position: relative;
  width: 65vw;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  border: ${(props) => (props.hasError ? '1px solid #d32f2f' : 'none')};
  border-radius: 8px;
  padding: ${(props) => (props.hasError ? '12px' : '0')};
`

const Option = styled.button<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  background: ${(props) => (props.selected ? '#E8F0FE' : '#F8F9FA')};
  border: 1px solid ${(props) => (props.selected ? '#1A73E8' : '#DFE1E5')};
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  flex: 0 1 auto;

  &:hover {
    background: #e8f0fe;
    border-color: #1a73e8;
  }
`

const ErrorMessage = styled.div`
  font-size: 16px;
  color: #d32f2f;
  margin-bottom: 8px;
`

interface ReasonForPurchaseVariantBProps {
  onSelect: (name: string, value: string, label: string) => void
}

const getLocalStorageKey = (quoteId: string) =>
  `reasonForPurchase_VariantB_${quoteId}`

export const ReasonForPurchaseVariantB = ({
  onSelect,
}: ReasonForPurchaseVariantBProps) => {
  const [field, meta, helpers] = useField({ name: 'reasonForPurchase' })

  const [fieldOther, metaOther, helpersOther] = useField({
    name: 'reasonForPurchaseOtherText',
  })

  const [fieldVariantB, metaVariantB, helpersVariantB] = useField({
    name: 'reasonForPurchase_VariantB',
  })

  const [fieldVariantBPosition, metaVariantBHelpers, helpersVariantBPosition] =
    useField({
      name: 'reasonForPurchase_VariantBPosition',
    })

  const { reasonOptionsRandomisedOrder } =
    useReasonOptionsRandomisedForExperiment()

  const { quoteId } = useRiskData()

  const setOriginalReasonForPurchase = (value: NewReasonOptionValue, label: NewReasonOptionLabel) => {    
    // Write derived value aka original value to state
    const originalValue = mapNewValueToOriginalValue(value)
    onSelect('reasonForPurchase', originalValue, label)
    helpers.setValue(originalValue)
  }
  
  const setOriginalOtherReason = (value: NewReasonOptionValue, shouldClear: boolean) => {
    // Write the other value to state if it should be written
    if (shouldWriteOtherReasonForCoverToBackend(value)) {
      helpersOther.setValue(
        getReasonForCoverLabelFromValue(value),
      )
      } else if (shouldClear) {
        helpersOther.setValue('')
      }
    }

  useEffect(() => {
    const savedValue = localStorage.getItem(getLocalStorageKey(quoteId)) as NewReasonOptionValue | null

    if (savedValue) {
      const index = reasonOptionsRandomisedOrder.findIndex((opt) => opt.value === savedValue)
      const option = reasonOptionsRandomisedOrder[index];

      setOriginalReasonForPurchase(savedValue, option.label)

      // Rehydrating (not selection), so don't clear if "Other" is selected, otherwise the customer will need to re-enter the reason.
      const shouldClear = savedValue !== "Other";
      setOriginalOtherReason(savedValue, shouldClear);

      // No need to write to storage as we've just got the value from there - but set the field value
      helpersVariantB.setValue(savedValue)
      
      // Select has 'Please select ...' at index 0 so positions need offsetting by +1
      // Conveniently, this means that not found (-1) is adjusted to 0
      const position = index + 1 
      // Store the position of the selected option so we can track it later
      helpersVariantBPosition.setValue(position)
    }
  }, [quoteId]) // Only run on mount and if quoteId changes

  return (
    <div>
      <H2>Please select your reason for cover</H2>
      {metaVariantB.touched && metaVariantB.error ? (
        <ErrorMessage>{metaVariantB.error}</ErrorMessage>
      ) : null}
      <Grid
        hasError={metaVariantB.touched && metaVariantB.error ? true : false}
      >
        {reasonOptionsRandomisedOrder.map((option, index) => (
          <Option
            key={option.value}
            selected={fieldVariantB.value === option.value}
            onClick={() => {
              setOriginalReasonForPurchase(option.value, option.label);

              // Selection has changed, so clear if "Other" is selected so customers can enter their reason
              setOriginalOtherReason(option.value, true);

              // Write the new version of the value to state
              onSelect('reasonForPurchase_VariantB', option.value, option.label)
              helpersVariantB.setValue(option.value)

              // Write the value to local storage so we can hydrate it later if they return to this form
              // We're sticking it in local storage as we aren't storing on the backend yet so have no other way of retrieving it
              localStorage.setItem(getLocalStorageKey(quoteId), option.value)

              // Store the position of the selected option so we can track it later
              helpersVariantBPosition.setValue(index)
              helpersVariantB.setError(undefined);
            }}
            type='button'
          >
            <span>{option.icon}</span>
            {option.label}
          </Option>
        ))}
      </Grid>
    </div>
  )
}
