import React from 'react'
import {
  PolicyDatesCoverLabel,
  PolicyDatesCoverDate,
  PolicyDatesContainer,
  PolicyDatesCol,
} from './styles'

interface Props {
  policyStart: string
  policyEnd: string
}

export const PolicyDates = ({ policyStart, policyEnd }: Props) => (
  <PolicyDatesContainer id='policyEndDateTimeSection'>
    <PolicyDatesCol>
      <PolicyDatesCoverLabel>Start date and time</PolicyDatesCoverLabel>
      <PolicyDatesCoverDate>
        {policyStart.split('at').join('\nat')}
      </PolicyDatesCoverDate>
    </PolicyDatesCol>
    <PolicyDatesCol>
      <PolicyDatesCoverLabel>End date and time</PolicyDatesCoverLabel>
      <PolicyDatesCoverDate>
        {policyEnd.split('at').join('\nat')}
      </PolicyDatesCoverDate>
    </PolicyDatesCol>
  </PolicyDatesContainer>
)
