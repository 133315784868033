import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import DriverDetailsForm from 'components/@forms/DriverDetails'
import { CommercialVehicleFormData } from 'components/@forms/DriverDetails/CommercialVehicleForm/CommercialVehicleForm.types'
import {
  DriverFormData,
  FullDriverFormData,
} from 'components/@forms/DriverDetails/DriverDetails.types'
import { LearnerFormData } from 'components/@forms/DriverDetails/LearnerForm/LearnerForm.types'
import { MotorcycleFormData } from 'components/@forms/DriverDetails/MotorcycleForm/MotorcycleForm.types'
import { PrivateCarFormData } from 'components/@forms/DriverDetails/PrivateCarForm/PrivateCarForm.types'
import { IdentityFormData } from 'components/@forms/DriverDetails/SubForms/IdentityCheckForm/IdentityCheck.types'
import SchemeTitle from 'components/SchemeTitle'
import { updatePostcode } from 'containers/Address/actions'
import {
  clearPrice,
  setQuoteJourneyPosition,
  updateConfusedQuickQuoteOptIn,
  updateDurationValue,
  updateStep,
} from 'containers/App/actions'
import { saveMarketingPreferences } from 'containers/MarketingPreferencesContainer/actions'
import {
  isButtonDisabled,
  updateQuote,
  updateRiskData,
  updateValidation,
} from 'containers/RiskDataCollectionPage/actions'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import {
  useAffiliate,
  useConfusedQuickQuote,
  useIdentity,
  useScheme,
  useVehicle,
} from 'hooks'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { QuoteJourneyPosition, Scheme } from 'types/global'
import { Grid, GridContainer } from 'unsemantic'
import queryString from 'query-string'
import { InitialStateType } from '../../initialState'
import DriverDetailsError from './components/DriverDetailsError'
import usePrivateCarOccupationExperiment from 'hooks/experiments/usePrivateCarOccupationExperiment'
import {
  getReasonForCoverLabelFromValue,
  useReasonForCoverExperiment,
} from 'hooks/experiments/ReasonForCoverExperimentConfig'
import { trackEvent } from 'utils/analytics-helpers'

const DriverDetails = () => {
  const { scheme } = useScheme()
  const dispatch = useDispatch()
  const { identityCheck } = useIdentity()
  const { vehicle, vehicleValue } = useVehicle()

  const shouldAskForOccupationForPrivateCar =
    usePrivateCarOccupationExperiment()

  const {
    reasonForCoverBucket,
    isVariantA: isReasonForCoverVariantA,
    isVariantB: isReasonForCoverVariantB,
  } = useReasonForCoverExperiment()

  const queryStringState: string = useSelector(
    (state: InitialStateType) => state.queryString,
  )

  const riskData = useSelector((state: InitialStateType) => state.riskData)

  const vehicleData = useSelector(
    (state: InitialStateType) => state.vehicle.selected,
  )

  const history = useHistory()

  const { isConfusedQuote, affiliate } = useAffiliate()
  const { validReasonsForPurchase } = useConfusedQuickQuote()

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search)
    const queryStringAffiliateRef = parsedQueryString.ref

    if (
      queryStringAffiliateRef !== undefined &&
      queryStringAffiliateRef !== '' &&
      queryStringAffiliateRef !== null &&
      affiliate.Ref !== null &&
      queryStringAffiliateRef !== affiliate.Ref
    ) {
      history.push(
        history.location.search.replace(
          queryStringAffiliateRef.toString(),
          affiliate.Ref,
        ),
      )
    }

    dispatch(updateStep(0))
  }, [dispatch, affiliate, history])

  // TODO: Refactor the update quote logic, as this is just a copy job from old logic
  const handleSubmit = (data: FullDriverFormData<DriverFormData>) => {
    // eslint-disable-next-line no-param-reassign
    data.vehicleValue = vehicleValue ?? data.vehicleValue

    dispatch(
      setQuoteJourneyPosition(QuoteJourneyPosition.CustomerDetailsSubmit),
    )
    dispatch(isButtonDisabled({ buttonName: 'driverDetails', disabled: true }))
    if (!(affiliate && affiliate.MarketingPreferencesDisabled)) {
      dispatch(saveMarketingPreferences())
    }
    dispatch(clearPrice())
    let payload = {}

    const durationData = {
      value: data.duration,
      type: data.durationType,
    }

    const policyStartData = {
      PolicyStartDate: {
        value: data.policyStartTime,
        manuallyEdited: data.manuallyChanged,
      },
    }

    const privateCarData = { ...(data as PrivateCarFormData) }

    const commercialVehicleData = {
      ...(data as CommercialVehicleFormData),
    }

    const learnerData = {
      ...(data as LearnerFormData),
    }

    const motorcycleData = {
      ...(data as MotorcycleFormData),
    }

    const identityData = {
      ...(data as IdentityFormData),
    }

    const occupationSchemes: Scheme[] = [
      'VehicleCommercialVehicleBaseScheme',
      'VehicleLearnerScheme',
    ]

    // Return if the occupation doesn't match what is in redux
    // We had issues previously with desyncing the occupation and redux
    // TODO: Remove this when we rewrite the redux logic
    if (
      occupationSchemes.indexOf(scheme as Scheme) > -1 &&
      commercialVehicleData.occupation !== riskData.Occupation.OccupationName
    ) {
      dispatch(
        isButtonDisabled({ buttonName: 'driverDetails', disabled: false }),
      )
      return
    }

    switch (scheme) {
      case 'VehiclePrivateCarBaseScheme':
      case 'VehiclePrivateCarImpoundedScheme':
        payload = {
          ...policyStartData,
          Forename: privateCarData.firstName,
          Surname: privateCarData.surname,

          AddressKey: privateCarData.addressKey,
          DateOfBirth: moment(privateCarData.dateOfBirth, 'DD/MM/YYYY').format(
            'yyyy-MM-DD',
          ),
          EmailAddress: privateCarData.emailAddress,
          Mobile: privateCarData.mobile.replace(/\s/g, ''),
          LicenceType: {
            ...riskData.LicenceType,
            value: privateCarData.licenceType,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: privateCarData.licenceHeldDuration,
          },
          Title: {
            ...riskData.Title,
            value: privateCarData.title,
          },
          ResidencyType: {
            ...riskData.ResidencyType,
            value: privateCarData.residencyType,
          },
          UKResidencyDuration: {
            ...riskData.UkResidencyDuration,
            value: privateCarData.ukResidencyDuration,
          },
          ReasonForPurchase: {
            ...riskData.ReasonForPurchase,
            value: privateCarData.reasonForPurchase,
          },
          ReasonForPurchaseOtherText: privateCarData.reasonForPurchaseOtherText,
        }

        // Track an event which logs the reason for purchase
        // Send the variant being used
        // Send the mapped value (found in privateCarData.reasonForPurchase)
        // Send the selected value from the variant specific fields (found in privateCarData.reasonForPurchase_Variant{A|B})
        trackEvent('ReasonForPurchase', {
          experimentBucket: reasonForCoverBucket,
          reasonForPurchase: privateCarData.reasonForPurchase,
          selectedReasonForPurchaseValue: isReasonForCoverVariantA
            ? privateCarData.reasonForPurchase_VariantA
            : isReasonForCoverVariantB
            ? privateCarData.reasonForPurchase_VariantB
            : privateCarData.reasonForPurchase,
          selectedReasonForPurchasePosition: isReasonForCoverVariantA
            ? privateCarData.reasonForPurchase_VariantAPosition
            : isReasonForCoverVariantB
            ? privateCarData.reasonForPurchase_VariantBPosition
            : undefined,
          selectedReasonForPurchaseDisplayText: isReasonForCoverVariantA
            ? getReasonForCoverLabelFromValue(
                privateCarData.reasonForPurchase_VariantA ?? '',
              )
            : isReasonForCoverVariantB
            ? getReasonForCoverLabelFromValue(
                privateCarData.reasonForPurchase_VariantB ?? '',
              )
            : privateCarData.reasonForPurchase,
        })

        if (isReasonForCoverVariantA) {
          payload = {
            ...payload,
            ReasonForPurchase_VariantA:
              privateCarData.reasonForPurchase_VariantA,
          }
        }

        if (isReasonForCoverVariantB) {
          payload = {
            ...payload,
            ReasonForPurchase_VariantB:
              privateCarData.reasonForPurchase_VariantB,
          }
        }

        if (shouldAskForOccupationForPrivateCar) {
          payload = {
            ...payload,
            Occupation: {
              ...riskData.Occupation,
              value: privateCarData.occupation,
            },
          }
        }
        break
      case 'VehicleCommercialVehicleBaseScheme':
      case 'VehicleCommercialVehicleImpoundedScheme':
      case 'VehicleCommercialVehicleCourierScheme':
        payload = {
          ...policyStartData,
          Forename: commercialVehicleData.firstName,
          Surname: commercialVehicleData.surname,

          AddressKey: commercialVehicleData.addressKey,
          DateOfBirth: moment(privateCarData.dateOfBirth, 'DD/MM/YYYY').format(
            'yyyy-MM-DD',
          ),
          EmailAddress: commercialVehicleData.emailAddress,
          Mobile: commercialVehicleData.mobile.replace(/\s/g, ''),
          LicenceType: {
            ...riskData.LicenceType,
            value: commercialVehicleData.licenceType,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: commercialVehicleData.licenceHeldDuration,
          },
          Title: {
            ...riskData.Title,
            value: commercialVehicleData.title,
          },
          ResidencyType: {
            ...riskData.ResidencyType,
            value: commercialVehicleData.residencyType,
          },
          UKResidencyDuration: {
            ...riskData.UkResidencyDuration,
            value: commercialVehicleData.ukResidencyDuration,
          },
          UseOfVehicle: {
            ...riskData.UseOfVehicle,
            value: commercialVehicleData.useOfVehicle,
          },
          BodyType: {
            ...riskData.BodyType,
            value: commercialVehicleData.bodyType,
          },
          Occupation: {
            ...riskData.Occupation,
            value: commercialVehicleData.occupation,
          },
          ReasonForPurchase: {
            ...riskData.ReasonForPurchase,
            value: privateCarData.reasonForPurchase,
          },
          ReasonForPurchaseOtherText: privateCarData.reasonForPurchaseOtherText,
        }
        break
      case 'VehicleLearnerScheme':
        payload = {
          ...policyStartData,
          Forename: learnerData.firstName,
          Surname: learnerData.surname,

          AddressKey: learnerData.addressKey,
          DateOfBirth: moment(privateCarData.dateOfBirth, 'DD/MM/YYYY').format(
            'yyyy-MM-DD',
          ),
          EmailAddress: learnerData.emailAddress,
          Mobile: learnerData.mobile.replace(/\s/g, ''),
          Title: {
            ...riskData.Title,
            value: learnerData.title,
          },
          OwnerOfVehicle: {
            ...riskData.OwnerOfVehicle,
            value: learnerData.ownerOfVehicle,
          },
          HasVehicleGotAnnualInsuranceEnumeration: {
            ...riskData.HasVehicleGotAnnualInsuranceEnumeration,
            value: learnerData.hasVehicleGotAnnualInsuranceEnumeration,
          },
          Occupation: {
            ...riskData.Occupation,
            value: learnerData.occupation,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: riskData.LicenceHeldDuration.value,
          },
        }
        break
      case 'VehicleMotorcycleLearnerAndFullLicenceScheme':
        payload = {
          ...policyStartData,
          LicenceType: {
            ...riskData.LicenceType,
            value: motorcycleData.licenceType,
          },
          Forename: motorcycleData.firstName,
          Surname: motorcycleData.surname,
          AddressKey: motorcycleData.addressKey,
          DateOfBirth: moment(privateCarData.dateOfBirth, 'DD/MM/YYYY').format(
            'yyyy-MM-DD',
          ),
          EmailAddress: motorcycleData.emailAddress,
          Mobile: motorcycleData.mobile.replace(/\s/g, ''),
          Title: {
            ...riskData.Title,
            value: motorcycleData.title,
          },
          Occupation: {
            ...riskData.Occupation,
            value: motorcycleData.occupation,
          },
          MotorcycleUseOfVehicle: {
            ...riskData.MotorcycleUseOfVehicle,
            value: motorcycleData.useOfVehicle,
          },
          UseOfVehicle: {
            ...riskData.MotorcycleUseOfVehicle,
            value: motorcycleData.useOfVehicle,
          },
          OwnerOfVehicle: {
            ...riskData.OwnerOfVehicle,
            value: motorcycleData.ownerOfVehicle,
          },
          MotorcycleOvernightParking: {
            ...riskData.MotorcycleOvernightParking,
            value: motorcycleData.overnightParking,
          },
          MotorcycleRidingExperience: {
            ...riskData.MotorcycleRidingExperience,
            value: motorcycleData.ridingExperience,
          },
          PreviouslyRiddenMotorcycleVehicle:
            vehicle.previousRiddenVehicle.VehicleId,
          MotorcycleEntitlement: {
            ...riskData.MotorcycleEntitlement,
            value: motorcycleData.motorcycleEntitlement,
          },
          MotorcycleReasonForTempcoverPurchase: {
            ...riskData.MotorcycleReasonForTempcoverPurchase,
            value: motorcycleData.reasonForPurchase,
          },
        }
        break
      default:
        // TODO: What to do in this case? Just return?
        break
    }
    switch (identityCheck) {
      case 'drivingLicence':
        payload = {
          ...payload,
          DrivingLicenceNumber: identityData.drivingLicenceNumber!.substring(
            12,
            16,
          ),
          DrivingLicenceNumberFirstPart:
            identityData.drivingLicenceNumber!.substring(0, 12),
        }
        break
      case 'passport':
        payload = {
          ...payload,
          PassportLineOne: identityData.passportLineOne,
          PassportLineTwo: identityData.passportLineTwo,
          PassportExpiryDate: identityData.passportExpiryDate,
        }
        break
      default:
        break
    }

    if (
      (privateCarData.confusedQuickQuoteOptIn === 'true' ||
        privateCarData.confusedQuickQuoteOptIn === 'false') &&
      validReasonsForPurchase.includes(riskData.ReasonForPurchase?.value || '')
    ) {
      dispatch(
        updateConfusedQuickQuoteOptIn(
          privateCarData.confusedQuickQuoteOptIn === 'true',
        ),
      )
    } else {
      dispatch(updateConfusedQuickQuoteOptIn(null))
    }
    dispatch(updateDurationValue(durationData))
    dispatch(updateRiskData(payload))
    // TODO: Re-evaluate this with new form logic later in the flow. As this is a bit hacky.
    // Manually updating validation for all fields as identity checks weren't progressing
    // due to not using new form logic
    dispatch(
      updateValidation({
        AddressError: '',
        BodyTypeError: '',
        DateOfBirthError: '',
        DrivingLicenceNumberError: '',
        EmailAddressError: '',
        ForenameError: '',
        HasVehicleGotAnnualInsuranceEnumerationError: '',
        LicenceHeldDurationError: '',
        LicenceTypeError: '',
        MobileError: '',
        OccupationError: '',
        OwnerOfVehicleError: '',
        PassportExpiryDateError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PostcodeError: '',
        ResidencyTypeError: '',
        SurnameError: '',
        TitleError: '',
        UkResidencyDurationError: '',
        UseOfVehicleError: '',
      }),
    )
    dispatch(
      updateVehicleValue({
        ...vehicleData.vehicleValue,
        value: data.vehicleValue,
      }),
    )
    dispatch(updatePostcode(data.postcode))
    dispatch(
      updateQuote({
        partialValidation: true,
        getPrice: true,
        callback: () => {
          history.push(`/quote/driving-licence${queryStringState}`)
        },
      }),
    )
  }

  // const handleVehicleUpdate = () => {
  //   dispatch(
  //     setQuoteJourneyPosition(QuoteJourneyPosition.CustomerVehicleChanged),
  //   )
  //   if (vehicle.searched.VehicleFound) {
  //     dispatch(setVehicleListener(vehicle.searched))
  //   } else {
  //     dispatch(createUnkownVehicle())
  //   }
  // }

  return (
    <div style={{ marginBottom: 16 }}>
      <GridContainerWrapper>
        {!isConfusedQuote && (
          <Grid desktop='100' parent>
            <SchemeTitle />
          </Grid>
        )}
        <Grid parent>
          <Grid parent desktop='100' tablet='100' mobile='100'>
            <Grid desktop='100' tablet='100' mobile='100'>
              {/* <VehicleSummary
                id='VehicleSummaryDesktop'
                handleUpdate={handleVehicleUpdate}
                isIndividual
              /> */}
            </Grid>
            <Grid desktop='100' tablet='100' mobile='100' parent />
          </Grid>
        </Grid>
        <GridContainer>
          <>
            <DriverDetailsError />
            <DriverDetailsForm onSubmit={handleSubmit} />
          </>
        </GridContainer>
      </GridContainerWrapper>
    </div>
  )
}

export default DriverDetails
